<template>
	<div>
		<div class="d-flex justify-space-between align-center mb-2 mt-6">
			<div class="input-wrap">
				<h5 class="mb-2">Pedidos</h5>
			</div>
			<div class="action-btn-wrap"></div>
		</div>
		<v-card color="secondary" class="pa-3">
			<v-row>
				<v-col cols="12" xl="6" lg="6" md="6" sm="4">
					<el-select
						v-model="search.customer_id"
						filterable
						remote
						placeholder="Cliente"
						:remote-method="searchRemoteCustomers"
						:loading="loading_search"
						loading-text="Cargando..."
						clearable
						@change="getRecords"
					>
						<el-option
							v-for="option in customers"
							:key="option.id"
							:value="option.id"
							:label="option.name"
						></el-option>
					</el-select>
				</v-col>
				<v-col cols="12" xl="3" lg="3" md="3" sm="4">
					<el-date-picker
						v-model="search.d_start"
						type="date"
						style="width: 100%;"
						placeholder="Desde"
						value-format="yyyy-MM-dd"
						format="dd/MM/yyyy"
						@change="changeDisabledDates"
					>
					</el-date-picker>
				</v-col>
				<v-col cols="12" xl="3" lg="3" md="3" sm="4">
					<el-date-picker
						v-model="search.d_end"
						type="date"
						style="width: 100%;"
						placeholder="Fecha"
						value-format="yyyy-MM-dd"
						format="dd/MM/yyyy"
						:picker-options="pickerOptionsDates"
						@change="getRecords"
					>
					</el-date-picker>
				</v-col>
			</v-row>

			<div class="row mt-2">
				<div class="col-xl-12">
					<div class="table-responsive">
						<v-simple-table dense>
							<template v-slot:default>
								<thead>
									<tr>
                                        <th>N°</th>
										<th>CANAL</th>
                                        <th>FECHA Y HORA</th>
                                        <th>CLIENTE</th>
                                        <!-- <th>Canal delivery</th> -->
                                        <th class="text-center">ESTADO</th>
                                        <th>DIRECCIÓN DE ENTREGA</th>
                                        <th class="text-right">TOTAL</th>
                                        <th class="text-right">ACCIONES</th>
									</tr>
								</thead>
								<tbody>
									<tr
										v-for="(row, index) in records"
										:index="customIndex(index)"
										:key="index"
									>
                                        <td>{{ index + 1 }}</td>
										<td>
                                            <v-icon v-if="row.channel_id == '01'" large color="info"
                                                >mdi-album</v-icon
                                            >
                                            <v-icon v-if="row.channel_id == '02'" large color="success"
                                                >mdi-whatsapp</v-icon
                                            >
                                            <v-icon v-if="row.channel_id == '04'" large color="accent"
                                                >mdi-phone</v-icon
                                            >
                                        </td>
                                        <td>{{ row.date_of_issue + ' ' + row.time_of_issue }}</td>
                                        <td>{{ row.customer }}</td>
                                        
                                        <td class="text-center">
                                            <v-chip color="warning" text-color="white" x-small v-if="row.state == '01'">
                                                {{ row.state_description }}
                                            </v-chip>
                                            <v-chip color="success" text-color="white" x-small v-if="row.state == '02'">
                                                {{ row.state_description }}
                                            </v-chip>
                                            <v-chip color="error" text-color="white" x-small v-if="row.state == '03'">
                                                {{ row.state_description }}
                                            </v-chip>
                                            <v-chip color="info" text-color="white" x-small v-if="row.state == '04'">
                                                {{ row.state_description }}
                                            </v-chip>
                                            <v-chip color="success" text-color="white" x-small v-if="row.state == '05'">
                                                {{ row.state_description }}
                                            </v-chip>
                                            <v-chip color="error" text-color="white" x-small v-if="row.state == '06'">
                                                {{ row.state_description }}
                                            </v-chip>
                                        </td>
                                        <td>{{ row.shipping.location.address }}</td>
                                        <td class="text-right">{{ row.currency_type }} {{ row.total }}</td>
                                        <td class="text-right">
                                            <v-tooltip top>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn
                                                        color="info"
                                                        class="ma-1"
                                                        fab
                                                        x-small
                                                        dark
                                                        @click.prevent="showDetails(row.id)"
                                                        v-on="on"
                                                    >
                                                        <v-icon>mdi-file-document</v-icon>
                                                    </v-btn>
                                                </template>
                                                <span>Ver detalles</span>
                                            </v-tooltip>
                                        </td>
									</tr>
								</tbody>
							</template>
						</v-simple-table>

						<div>
							<v-pagination
								@next="getRecords"
								@previous="getRecords"
								@input="getRecords"
								:length="pagination.last_page"
								:total-visible="7"
								v-model="pagination.current_page"
								circle
								prev-icon="mdi-chevron-left"
								next-icon="mdi-chevron-right"
							></v-pagination>
						</div>
					</div>
				</div>
			</div>
		</v-card>
		<!-- <generate-form
			:showDialog.sync="showDialogGenerate"
			:recordId="recordId"
			:showGenerate="true"
			:showClose="true"
		></generate-form> -->
        <details-form :showDetailsDialog.sync="showDetailsDialog" :recordId="recordId">
		</details-form>
	</div>
</template>

<script>
import DetailsForm from './Component/Details';
// import GenerateForm from './Component/Generate';
import { confirm } from 'Mixins/confirm';
import queryString from 'query-string';
import dayjs from 'dayjs';

export default {
	components: { DetailsForm },
	mixins: [confirm],
	data() {
		return {
			resource: 'orders-list',
			showDialogGenerate: false,
            showDetailsDialog: false,
			loading_search: false,
			recordId: null,
			records: [],
			customers: [],
			pagination: {},
			search: {
				customer_id: null,
				d_start: dayjs()
					.startOf('month')
					.format('YYYY-MM-DD'),
				d_end: dayjs().format('YYYY-MM-DD'),
			},
			pickerOptionsDates: {
				disabledDate: (time) => {
					time = dayjs(time).format('YYYY-MM-DD');
					return this.search.d_start > time;
				},
			},
			pickerOptionsDateOfIssue: {
				disabledDate: (time) => {
					time = dayjs(time).format('YYYY-MM-DD');
					return dayjs().format('YYYY-MM-DD') < time;
				},
			},
		};
	},
	async created() {
		await this.getRecords();

		this.$eventHub.$on('reloadData', (resource) => {
			if (resource == this.resource) {
				this.getRecords();
			}
		});
	},
	methods: {
		customIndex(index) {
			return this.pagination.per_page * (this.pagination.current_page - 1) + index + 1;
		},
		getRecords() {
			this.loading = true;
			return this.$http
				.get(`/${this.resource}/records?${this.getQueryParameters()}`)
				.then((response) => {
					this.records = response.data.data;
					this.pagination = response.data.meta;
					this.pagination.per_page = parseInt(response.data.meta.per_page);

					this.loading = false;
				});
		},
		getQueryParameters() {
			return queryString.stringify({
				page: this.pagination.current_page,
				limit: this.limit,
				...this.search,
			});
		},
		clickCreate() {
			location.href = `/${this.resource}/tables`;
		},
		clickGenerate(recordId) {
			this.recordId = recordId;
			this.showDialogGenerate = true;
		},
		searchRemoteCustomers(input) {
			if (input.length > 0) {
				this.loading_search = true;
				let parameters = `input=${input}`;
				this.$http
					.get(`/documents/search/customers-only?${parameters}`)
					.then((response) => {
						this.customers = response.data;
						this.loading_search = false;
					});
			} else {
				this.customers = [];
			}
		},
        showDetails(recordId) {
			this.recordId = recordId;
			this.showDetailsDialog = true;
		},
		
		changeDisabledDates() {
			this.search.date_of_issue = null;
			if (this.search.d_end < this.search.d_start) {
				this.search.d_end = this.search.d_start;
			}

			this.getRecords();
		},
	},
};
</script>
